import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  OnChanges,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DomUtil, DateTimeUtil } from '../../utils';
import { Field, Translatable } from '../field/field.component';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { ListItem } from '../../models/list-item.model';
import { KeyboardService } from '@lims-common-ux/lux';

// The cl-list component is used only in customer messages
@Component({
  selector: 'cl-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: Field.Providers(ListComponent),
})
export class ListComponent<T extends { label: string } = any>
  extends Field
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input()
  formFieldLabel: string;
  @Input()
  formFieldName: string;
  @Input()
  formFieldRequired: boolean;
  @Input()
  maxTextSize: number | null | undefined | false = undefined;
  @Input()
  maxListItems: number;
  @Input()
  maxItemsMessage: string;
  @Input()
  maxTextSizeMessage: string = 'ERRORS_AND_FEEDBACK.MAX_TEXT_SIZE';
  @Input()
  availableListItems: any[] = [];
  @Input()
  matchAttributes: string[];
  @Input()
  allowMultipleEntry: boolean = false;
  @Input()
  allowFreeText: boolean = false;
  @Input()
  textAttribute: string;
  @Input()
  showNoMatches: boolean = true;
  @Input()
  searchChar: string = '@';
  @Input()
  allowEdit: boolean = false;
  @Input()
  allowMultipleLines: boolean = false;
  @Input()
  placeholder: string;
  @Input() errorValidations: (string | Translatable)[];
  listErrorValidations: any[];
  incompleteInteractionError = 'ERRORS_AND_FEEDBACK.CONTAINS_ERRORS';
  @Input()
  processingDelete: boolean = false;
  actionSub: Subscription;

  @Output()
  asyncDelete = new EventEmitter();

  @ViewChild('itemInput', { static: true }) itemInput: AutocompleteComponent;
  @ViewChild('childItems', { static: true }) childItems;

  _listItems: ListItem<T>[] = [];
  set listItems(listItems) {
    const last = this._listItems;

    this._listItems = listItems;

    if (this._onChange && last !== listItems && listItems !== null) {
      this._onChange(listItems);
    }
  }

  get listItems() {
    return this._listItems;
  }

  currentItemInput: string = '';
  showMaxSizeHint: boolean = false;
  showNoMatchesFound: boolean = false;
  showMaxTextSizeHint: boolean = false;

  _timeouts = {};
  _timeout_ids = 1;

  _selected;
  _blur_timeout;
  _searchString;
  _autoCompleteItems = [];

  constructor(
    public element: ElementRef,
    private keyboardService: KeyboardService
  ) {
    super(element);
  }

  ngOnInit() {
    this.ngOnChanges();

    this.actionSub = this.itemInput.action.subscribe((event) => {
      if (event instanceof FocusEvent && event.type === 'focusout') {
        this.onBlur(event);
      }
    });
  }

  ngOnChanges() {
    const matchAttributes = this.matchAttributes,
      searchChar = this.searchChar,
      autoCompleteItems = (this._autoCompleteItems = this.availableListItems.map((item) => ({
        __proto__: item,
        $value: item,
      })));

    if (matchAttributes) {
      matchAttributes.forEach((attribute) => {
        const ln: number = autoCompleteItems.length;
        let i, item;
        for (i = 0; i < ln; i++) {
          item = autoCompleteItems[i];
          item[attribute] = item.shortCode ? `${searchChar}${item[attribute]}` : `${item[attribute]}`;
        }
      });
    }
  }

  ngOnDestroy() {
    this.actionSub.unsubscribe();
  }

  add(entered, recommended?) {
    const maxListItems = this.maxListItems,
      listItems = this.listItems.slice();

    let failed = false,
      parsedMessages,
      message,
      i,
      ln;

    // Accept a string for freetext or @1@2 functionality
    if (typeof entered === 'string') {
      if (entered.length > Number(this.maxTextSize)) {
        failed = true;
      } else {
        parsedMessages = this.parseInput(entered);

        ln = parsedMessages.length;

        if (ln) {
          for (i = 0; i < ln; i++) {
            message = parsedMessages[i];
            if (listItems.length < maxListItems) {
              let duplicate = false;
              listItems.forEach((item) => {
                // Freetext will not have a message.value, and dupes are allowed
                if (!this.allowFreeText && message.value && message.value['shortCode'] === item.value['shortCode']) {
                  if (recommended) {
                    setTimeout(() => {
                      this.delete(item);
                    }, 0);
                  } else {
                    duplicate = true;
                  }
                }
              });

              if (!duplicate) {
                if (recommended) {
                  message.value.accountType = 'SYSTEM';
                }

                listItems.unshift({
                  value: message.value,
                  info: {
                    dateCreated: DateTimeUtil.getCurrentUTCString(),
                    modified: message.modified,
                    editing: false,
                    text: message.message,
                    initialText: message.message,
                    recommended: recommended || false,
                  },
                });
              }
            } else {
              failed = true;
              break;
            }
          }
        } else {
          this.showNoMatchesFound = false;
        }
      }
    } else if (this.itemInput.isMatchesVisible()) {
      if (listItems.length < maxListItems) {
        // disallow duplicate additions to list
        let duplicate = false;
        listItems.forEach((item) => {
          if (entered.$value['shortCode'] === item.value['shortCode']) {
            duplicate = true;
          }
        });

        if (!duplicate) {
          listItems.unshift({
            value: entered.$value,
            info: {
              dateCreated: DateTimeUtil.getCurrentUTCString(),
              modified: false,
              editing: false,
              text: entered[this.textAttribute] || '',
              initialText: entered[this.textAttribute] || '',
              recommended: recommended || false,
            },
          });
        }
      } else {
        failed = true;
      }
    }

    if (!failed) {
      this.itemInput.updateInput('');
    }

    this.showNoMatches = false;
    this.update(listItems);
    this.showMaxSizeHint = this.listItems.length === this.maxListItems;
    this.reset();
  }

  parseInput(enteredString) {
    // the autocomplete input string is split into groups of (@[^@]+)
    // so suppose @1@2, the string is split into ["", @1,@2]
    // index 0 will always be preceeding freeText or an emptyString
    // @1, @2 may or may not match exact text defined in the system, if they are non-matching they are
    // concatenated onto a running freeText value
    const split = this.searchChar
        ? enteredString.split(new RegExp(`(${this.searchChar}[^\\${this.searchChar}]+)`))
        : [enteredString],
      result = [];

    let i,
      current,
      filtered,
      lookup,
      message,
      match,
      freeText = '';

    // iterate backwards through the split groups because we want to associate
    // freeText is always associated with a preceeding lookup (if there is one)
    // non-matching lookups are treated as freeText
    // we do not process index 0 here because its always freeText and will never associate
    // to a lookup
    for (i = split.length - 1; i > 0; i--) {
      current = split[i];
      if (!current) {
        continue;
      }

      // split the current item on "<space>" - so we can detect non searchChar freeText
      filtered =
        current
          .trim()
          .split(' ')
          .filter((v) => v) || [];
      lookup = filtered[0];

      // try and find a match to the lookup
      match = this.matchExact(lookup);

      // if a message is found, the internal value exists
      if (match) {
        message = match[this.textAttribute] || '';
        // remove the lookup from additional freeText
        filtered = filtered.slice(1);

        // preceed freeText with a space
        if (filtered.length) {
          filtered.unshift('');
        }
        if (freeText) {
          freeText = freeText.trim();
        }

        let modified: boolean = false;

        if (filtered.length || freeText) {
          modified = true;
        }

        // put each result into the first slot
        const parsedMessage = this.allowFreeText
          ? message + filtered.join(' ') + (freeText ? ` ${freeText}` : '')
          : message;
        result.unshift({
          value: match.$value,
          message: parsedMessage,
          modified: modified,
        });
        freeText = '';
      } else {
        // the match was not found, so prepend the raw current text to the free text
        freeText = current + ' ' + freeText;
      }
    }

    // process index 0 which is always freeText
    freeText = split[0] + (split[0] ? ' ' : '') + freeText;

    if (this.allowFreeText && freeText && freeText.split(' ').filter((v) => v).length) {
      result.push({
        value: undefined,
        message: freeText.trim(),
        modified: false,
      });
    }
    return result;
  }

  listNoMatchesCallback($event) {
    if (!this.allowFreeText && $event) {
      this.listErrorValidations = ['ERRORS_AND_FEEDBACK.NO_MATCHES'];
      this.showNoMatches = true;
      this.showNoMatchesFound = $event;
    } else if (this.listErrorValidations && !this.listErrorValidations.length) {
      this.showNoMatchesFound = false;
    }

    this._onChange(this.listItems);

    return $event;
  }

  matchExact(input) {
    const upperInput = input.trim().toUpperCase(),
      items = this._autoCompleteItems,
      attributes = this.matchAttributes || [],
      ln: number = attributes.length;

    let item, attribute, i, j, jln;

    for (i = 0; i < ln; i++) {
      attribute = attributes[i];

      jln = items.length;

      for (j = 0; j < jln; j++) {
        item = items[j];
        if (item[attribute] && item[attribute].trim().toUpperCase() === upperInput) {
          return item;
        }
      }
    }
  }

  _getEvent(item): {
    target?;
    key?;
    stopPropagation?;
    preventDefault?;
    shiftKey?;
  } {
    if (!this.allowEdit) {
      return;
    }

    const index = this.listItems.indexOf(item);
    let editable = null;

    if (index !== -1) {
      ({ editable } = this.queryDown(this.childItems.nativeElement.children[index], { editable: true }));
    }
    return {
      target: editable,
      key: '',
      stopPropagation: () => {},
      preventDefault: () => {},
    };
  }

  // manages async changes to each individual list item
  _dispatch(item, fn) {
    if (!item.$id) {
      item.$id = this._timeout_ids++;
    }
    if (this._timeouts[item.$id]) {
      clearTimeout(this._timeouts[item.$id]);
    }
    this._timeouts[item.$id] = setTimeout(() => {
      this._timeouts[item.$id] = false;
      fn();
    }, 0);
  }

  edit(item, $event = this._getEvent(item)) {
    if (!item || item.info.deleted) {
      return;
    }
    // initiate focus on item
    this.focusItem(item, $event);

    const { row } = this.queryUp($event.target, { row: true }),
      { editable } = this.queryDown(row, { editable: true });

    if (!item.info.editing) {
      item.info.editing = true;
      item.info.initialText = item.info.text;
    }

    this._dispatch(item, () => {
      this._selected = item;
      editable.focus();
      DomUtil.deselectAll();
      DomUtil.setCursor($event.target, $event.target.innerText.length);
    });
  }

  blurItem(item, $event = this._getEvent(item)) {
    const { editable, row } = this.queryUp($event.target, {
      row: true,
      editable: true,
    });

    if (this._blur_timeout && row === this._blur_timeout.target) {
      clearTimeout(this._blur_timeout.timeout);
    }

    // when the editable field is blurred, clear the _selected item
    if (this._selected === item && editable) {
      this._selected = null;
    }
    // _blur_timeout is used to manage focus changes between removeIcon, cancelIcon, and editableText.  If focus moves from editableText
    // to an icon, we don't want to confirmEdit
    this._blur_timeout = {
      target: row,
      timeout: setTimeout(() => {
        if (item.info.editing) {
          this.confirmEdit(item, $event);
          this._dispatch(item, () => {});
        }
      }),
    };
  }

  focusItem(item, $event = this._getEvent(item)) {
    const { row } = this.queryUp($event.target, { row: true });

    if (this._blur_timeout && row === this._blur_timeout.target) {
      clearTimeout(this._blur_timeout.timeout);
      this._blur_timeout = false;
    }
  }

  confirmEdit(item, $event = this._getEvent(item)) {
    // clear out any pending async behaviors on the item
    this._dispatch(item, () => {});
    if (item.info.error) {
      return;
    }

    // new String causes angular to sync the state to the UI
    if (item.info.text !== item.info.initialText) {
      item.info.modified = true;
    }

    item.info.editing = false;

    if (!item.info.text.length) {
      this.delete(item);
    }

    this.update(this.listItems.slice());
  }

  cancelEdit(item, $event = this._getEvent(item)) {
    // clear out any pending async behaviors on the item
    this._dispatch(item, () => {});

    const { row } = this.queryUp($event.target, { row: true }),
      { editable } = this.queryDown(row, { editable: true });

    item.info.editing = false;
    editable.innerText = item.info.initialText;
    item.info.text = item.info.initialText;
    item.info.error = item.info.text.length > this.maxTextSize;
    this.shouldShowMaxTextSize();
    this.keyboardService.focusNext();
  }

  delete(item, event?) {
    // select the next item if possible -- if not we need to move focus into the input
    const index = this.listItems.indexOf(item),
      listItems = this.listItems.slice();

    if (!item.info.recommended) {
      item.info.deleted = true;
      listItems.splice(index, 1);
      this.update(listItems);
      this.showMaxSizeHint = listItems.length === this.maxListItems;
      this.shouldShowMaxTextSize();
    } else {
      this.asyncDelete.emit(item);
    }

    // When removing a value via keyboard, set focus to the next available editable item.
    // When removing a value via mouse, set focus to the component input.
    // Focus rules are tested in acceptance tests.
    if (event?.pointerId) {
      const focusableParent = DomUtil.queryUp(event.target, 'a');
      // @ts-ignore
      focusableParent?.focus();
    }

    // Focus the next available, focusable element in the list, or the component input if no list items are left
    if (this.listItems.length > 0 && index !== this.listItems.length) {
      this.keyboardService.focusNext();
    } else if (this.listItems.length !== 0) {
      this.keyboardService.focusPrev();
    } else {
      this.focusInput();
    }
  }

  reset() {
    this.currentItemInput = '';
  }

  resetList() {
    this.listErrorValidations = [];
    this.showNoMatches = false;
    this.itemInput.resetAutocomplete();
    this.showMaxTextSizeHint = false;
    this.showMaxSizeHint = false;
    this.showNoMatchesFound = false;
    this.update([]);
  }

  update(listItems) {
    this.showNoMatches = false;
    this.listItems = listItems;
  }

  purgeRecommendations() {
    this.listItems = this.listItems.filter((customerMessage: ListItem) => {
      return !customerMessage.info.recommended && customerMessage.value.accountType !== 'SYSTEM';
    });
  }

  onInputChange(value) {
    if (
      !value ||
      !this._searchString ||
      (this.listErrorValidations.length && this._searchString.length > value.length)
    ) {
      this.listErrorValidations = [];
    }

    this._searchString = value;
    this.shouldShowMaxTextSize();
  }

  shouldShowMaxTextSize() {
    const maxTextSize = this.maxTextSize;
    let showMaxTextSizeHint = false;

    if ((this._searchString || '').length > Number(maxTextSize)) {
      showMaxTextSizeHint = true;
    } else {
      showMaxTextSizeHint = this.containsMaxTextSizeError();
    }

    this.showMaxTextSizeHint = showMaxTextSizeHint;
  }

  hasIncompleteInteractionError(): boolean {
    return this.listErrorValidations?.indexOf(this.incompleteInteractionError) > -1;
  }

  containsMaxTextSizeError(): boolean {
    const listItems = this.listItems || [],
      maxTextSize = this.maxTextSize,
      ln: number = listItems.length;
    let i;

    for (i = 0; i < ln; i++) {
      if (listItems[i].info.text.length > Number(maxTextSize)) {
        return true;
      }
    }
    return false;
  }

  getMicroText() {
    const result = [];
    if (this.showMaxSizeHint) {
      result.push(this.maxItemsMessage);
    }
    if (this.showMaxTextSizeHint) {
      result.push({
        text: this.maxTextSizeMessage,
        args: { value: this.maxTextSize },
      });
    }
    return result;
  }

  getListItemsClass(item) {
    return `list-items ${item.info.error ? 'error' : ''} ${item.info.recommended ? 'recommended' : ''}`;
  }

  getEditableClass(item) {
    let classes = 'list-item-data-value';
    classes += item === this._selected ? ' selected' : '';
    classes += this.allowEdit ? ' focusable' : '';
    return classes;
  }

  // using this so all dom querys get piped through a single call
  queryUp(element, { focusable = false, row = false, editable = false }) {
    return {
      focusable: focusable ? (DomUtil.queryUp(element, '.focusable') as HTMLElement) : null,
      row: row ? (DomUtil.queryUp(element, '.list-items') as HTMLElement) : null,
      editable: editable ? (DomUtil.queryUp(element, '.list-item-data-value') as HTMLElement) : null,
    };
  }

  queryDown(element, { focusables = false, editable = false }) {
    return {
      focusables: focusables ? element.querySelectorAll('.focusable') : null,
      editable: editable ? (element.querySelector('.list-item-data-value') as HTMLElement) : null,
    };
  }

  onInput(item, $event) {
    item.info.text = $event.target.innerText.trim();
    item.info.error = item.info.text.length > this.maxTextSize;
    this.shouldShowMaxTextSize();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown($event) {
    const target = $event.target;
    this.queryUp(target, { focusable: true, editable: true });
  }

  setDisabledState(disabled) {
    this.itemInput.setDisabledState(disabled);
  }

  registerOnTouched(onTouched) {
    this.itemInput.registerOnTouched(onTouched);
  }

  writeValue(value) {
    if (value) {
      this.listItems = value;
    } else {
      this.listItems = [];
      this.itemInput.clInput.value = '';
    }
  }
  focusInput() {
    this.itemInput.focusInput();
  }

  blurInput() {
    this.itemInput.blurInput();
  }

  onBlur($event) {
    // When a user enters a value into the input, but does not commit a value to the form
    // to be saved on the order, then the input is in an error state.
    if (this.hasIncompleteInteractionError()) {
      this.listErrorValidations.splice(this.listErrorValidations.indexOf(this.incompleteInteractionError), 1);
    }
    if (this.itemInput.clInput.input.nativeElement.value.trim()) {
      this.listErrorValidations.push(this.incompleteInteractionError);
    }

    this._onChange(this.listItems);
  }
}
