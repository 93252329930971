import { Component, Input, HostListener, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CancelReason, CanceledTest, Test, TestCodeSearchResponse } from '../../models/test.model';
import { SearchSelectComponent } from '../search-select/search-select.component';
import { TestCodesService } from '../../../order-entry/test-codes/test-codes.service';
import { SearchService } from '../../services/search/search.service';
import { AnimalTypeInputValue } from '../../models/animal-type.model';
import { TranslateService } from '@ngx-translate/core';
import { OrderEntryService } from '../../../order-entry/order-entry.service';
import { KeyboardService, Link, PendingRequestInterceptor } from '@lims-common-ux/lux';
import { DomUtil } from '../../utils';

export interface TestSearchResource {
  name: string;
  testId: string;
  testCode: string;
  _links: {
    self: Link;
  };
}

@Component({
  selector: 'cl-test-code-field',
  templateUrl: './test-code-field.component.html',
  styleUrls: ['../search-select/search-select.component.scss', './test-code-field.component.scss'],
  providers: [
    SearchService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TestCodeFieldComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TestCodeFieldComponent,
      multi: true,
    },
  ],
})
export class TestCodeFieldComponent extends SearchSelectComponent<Test> implements ControlValueAccessor {
  @ViewChild('input')
  input!: ElementRef;
  // value can be an empty list, a list of test codes or the missing info glyph (?)
  value: Test[] | string = [];
  @Input()
  searchUrl: string;
  @Input()
  operationalRegionCode: string;
  missingInformationGlyph = this.orderEntryService.missingInformationGlyph;
  @Input()
  animalType: AnimalTypeInputValue;
  @Input()
  canceledTests: CanceledTest[] = [];
  @Input()
  disableInput: boolean = false;

  // when this is true we will auto select the first returned item, and move to the next field
  autoselectShortCodeMatch = false;
  // when in edit mode assign tests can be deleted
  // also the delete icon will be visible
  edit = false;
  hotKeyInstructionMicroText = 'HOT_KEY_INSTRUCTIONS.EDIT';
  missingInfoValueWarning = 'ERRORS_AND_FEEDBACK.INVALID_MISSING_INFORMATION_TEST_CODES';
  microText = [];
  requestingTest = false;
  warningSameTestCode: string;
  searchSub: Subscription;

  @Input()
  cancelReasons: CancelReason[];

  // update event does not fire on missing info glyph
  @Output()
  update = new EventEmitter<Test[] | string>();

  // if there has been a new test added
  @Output()
  newTests = new EventEmitter<boolean>();

  @HostListener('keydown.alt.e', ['$event'])
  onAltE(event) {
    this.stopEvent(event);
    this.toggleEdit();
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  constructor(
    public orderEntryService: OrderEntryService,
    public searchService: SearchService<TestCodeSearchResponse | Test>,
    public testCodesService: TestCodesService,
    public translate: TranslateService,
    private keyboardService: KeyboardService
  ) {
    super();
  }

  getLabelMaxWidth(addedTest: Test): string {
    let width = '80%';
    if (addedTest['reviewAlert'] || addedTest['reviewPositive']) {
      width = '75%';
    }

    return width;
  }

  toggleEdit() {
    this.edit = !this.edit;
    this.warningSameTestCode = null;

    if (!this.edit) {
      this.focusInput();
    }
  }

  handleEnter(event) {
    if (this.displayedData.length) {
      this.selectItem(this.displayedData[0]);
    } else if (!this.displayedData.length && this.displayValue && this.searching) {
      this.autoselectShortCodeMatch = true;
    }
  }

  // Invalidate input if the user leaves the input after initiating search, but before selecting a value
  handleIncompleteInteraction() {
    requestAnimationFrame(() => {
      if (!this.isInputActiveElement() && !this.value) {
        this.onValueChange(null);
        this.autoselectShortCodeMatch = false;
        this.onChange(null);
        this.hideOptions();
      }
    });
  }

  handleSearch(value: string) {
    this.hideOptions();
    this.warningSameTestCode = null;

    if (!value) {
      this.searching = false;
      this.autoselectShortCodeMatch = false;

      if (this.value === this.missingInformationGlyph) {
        this.onValueChange(null); // sets value to empty array
        this.onChange(this.value);
      } else {
        this.updateMicroText();
      }
      return;
    }

    if (value !== this.missingInformationGlyph) {
      this.searching = true;

      if (this.value !== this.missingInformationGlyph) {
        this.onChange(this.value);
      }

      let animalTypeCode = '';

      if (this.animalType) {
        animalTypeCode = this.animalType.animalTypeCode;
      }

      if (this.searchSub) {
        this.searchService.context?.set(PendingRequestInterceptor.doNotLogRequestFailure, true);
        this.searchSub.unsubscribe();
      }

      // The Test selected from this query will have a self _link used in addTests()
      // which gets the full test form with some data points we need for order validation
      this.searchSub = this.searchService
        .search({
          url: this.testCodesService.searchUrl,
          value: value,
          // URL parameters that will be added to the given searchUrl
          params: () => ({
            // make sure we dont append an empty animalTypeCode parameter if we do not yet have an AnimalType
            ...(animalTypeCode && { animalTypeCode }),
          }),
          // URL parameter matches that are already existing in the given searchUrl
          interpolate: () => ({
            operationalRegionCode: this.operationalRegionCode,
          }),
        })
        .subscribe((testCodeResas) => {
          const res: TestCodeSearchResponse = testCodeResas as TestCodeSearchResponse;
          this.searchSub = null;

          if (res._embedded && this.displayValue) {
            if (this.autoselectShortCodeMatch) {
              this.autoselectShortCodeMatch = false;
              this.hideOptions();
              this.selectItem(res._embedded.testSearchResources[0]);
            } else {
              this.displayedData = res._embedded.testSearchResources;
              this.searching = false;
            }
          } else {
            this.searching = false;
            this.autoselectShortCodeMatch = false;
            this.hideOptions();
            this.onChange(this.value);
          }
        });

      this.handleIncompleteInteraction();
    } else {
      this.autoselectShortCodeMatch = false;
      // we do NOT write '?' to the component if there have been tests added
      if (Array.isArray(this.value) && !this.value.length) {
        this.onValueChange(this.missingInformationGlyph);

        setTimeout(() => {
          this.goToNext.next(true);
        });
      } else {
        this.updateMicroText();
      }
    }
  }

  // Overriding select as we wait for additional calls to complete before we hide/select the item.
  selectItem(_item: TestSearchResource) {
    const test = Test.createTest(_item as Test);
    this.addTests([test]);
  }

  prepareTestRequests(tests: Test[]): Observable<any>[] {
    this.searching = true;

    const testReqs: Observable<any>[] = [];

    tests.forEach((test) => {
      testReqs.push(this.testCodesService.getTestCode(test));
    });

    this.requestingTest = true;

    return testReqs;
  }

  addTests(tests: Test[]) {
    const testReqs = this.prepareTestRequests(tests);

    return forkJoin(testReqs).subscribe((testResArr) => {
      this.writeAddedTests(testResArr);
    });
  }

  writeAddedTests(testResArr) {
    this.displayValue = '';

    testResArr.forEach((testRes, index) => {
      const test: Test = Test.createTest(testRes);

      if (this.isAddOnTest(test)) {
        test.isAddOn = true;
      }

      const emitUpdate = index === testResArr.length - 1;
      this.onValueChange([test], emitUpdate);
    });

    this.hideOptions();

    this.requestingTest = false;
    this.searching = false;
  }

  handleBackspace() {
    setTimeout(() => {
      this.onChange(this.value);
    });
  }

  handleFocusOut($event) {
    super.handleFocusOut($event);

    if (!this.value.length) {
      this.onChange(this.value);
    }
  }

  updateMicroText() {
    const newTextArr: string[] = [];

    // we have a test and a missing info glyph has been entered
    if (this.value.length && Array.isArray(this.value) && this.displayValue === this.missingInformationGlyph) {
      this.translate
        .get(this.missingInfoValueWarning, {
          value: this.missingInformationGlyph,
        })
        .subscribe((i18nVal) => {
          newTextArr.push(i18nVal);
        });
    }

    // we have a Test so show the edit microText
    if (this.value.length && this.value !== this.missingInformationGlyph) {
      newTextArr.push(this.hotKeyInstructionMicroText);
    }

    this.microText = newTextArr;
  }

  emitUpdate() {
    if (this.hasNewTest()) {
      this.newTests.emit(true);
    } else {
      this.newTests.emit(false);
    }

    this.update.emit(this.value);
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

  removeTest(value: Test, event?) {
    if (!this.edit) {
      return false;
    }

    const orderedTests = (this.value || []).slice() as Test[];
    const index: number = orderedTests.indexOf(value, 0);
    // Handle accession edits with add-ons to allow remove test vs. cancel test presentation and behaviors
    // @ts-ignore
    const allRemovables = this.value.filter((item) => this.isRemovable(item.testId));
    const isLastRemovable =
      this.testCodesService.isExistingAccession() && this.isRemovable(value.testId) && allRemovables.length === 1;

    // @ts-ignore
    this.value = this.value.filter((existingTest) => {
      return existingTest !== value;
    });

    // When removing a value via keyboard, set focus to the next available editable item.
    // When removing a value via mouse, set focus to the component input.
    // Focus rules are tested in acceptance tests.
    if (event?.pointerId) {
      const focusableParent = DomUtil.queryUp(event.target, 'a');
      // @ts-ignore
      focusableParent?.focus();
    }

    // Focus the next available, focusable element in the list, or the component input if no list items are left
    if (this.value.length > 0 && index !== this.value.length) {
      this.keyboardService.focusNext();
    } else if (this.value.length !== 0) {
      this.keyboardService.focusPrev();
      // Updating an accession and attempting to remove the last and only removable item
      if (isLastRemovable) {
        this.keyboardService.focusPrev();
      }
    } else {
      this.focusInput();
    }

    // Order matters here. We need to set focus before emitting output and triggering onChange!
    this.emitUpdate();

    this.onChange(this.value);

    // When there are no remaining items, toggle edit mode to false
    if (!this.value.length) {
      this.edit = false;
    }

    this.updateMicroText();
  }

  writeValue(value: Test[] | string) {
    if (value === null) {
      this.resetInputValue();
      this.warningSameTestCode = null;
    }

    this.onValueChange(value, true, false);
  }

  // overriding the onValueChange function from the base component
  onValueChange(value: Test[] | string, emitUpdate = true, emitChange = true) {
    if (value && value.length === 1 && value !== this.missingInformationGlyph) {
      if (!this.value || this.value === this.missingInformationGlyph) {
        this.value = [];
      }

      if (Array.isArray(value) && Array.isArray(this.value)) {
        const test = value[0];
        const sameTestCode = this.thereIsTestWithSameTestCode(test);
        const newTest = test;
        const testWithSameTestCodeNotCanceled = this.isTestWithSameTestCodeNotCanceled(newTest);

        if (sameTestCode && testWithSameTestCodeNotCanceled) {
          this.warningSameTestCode = testWithSameTestCodeNotCanceled.testCode;
        } else if (!sameTestCode) {
          // Add a Test to the order
          this.value.push(newTest);

          if (emitUpdate) {
            this.emitUpdate();
          }
        } else if ((sameTestCode && !testWithSameTestCodeNotCanceled) || this.isUncancelable(newTest)) {
          // Uncancel a Test
          newTest.setIsAddOn(true);
          newTest.setIsUncanceled();

          this.value.push(newTest);

          if (emitUpdate) {
            this.emitUpdate();
          }
        }
      }
    } else if (Array.isArray(value) && value.length > 1) {
      this.value = value;
      this.emitUpdate();
    } else if (value === this.missingInformationGlyph) {
      this.value = this.missingInformationGlyph;
      this.input.nativeElement.value = `${this.missingInformationGlyph}`;
      if (emitUpdate) {
        this.emitUpdate();
      }
    } else {
      this.value = [];
    }

    if (this.value.length && emitChange) {
      this.onChange(this.value);
    }

    if (!this.value.length || this.value === this.missingInformationGlyph) {
      this.edit = false;
    }

    this.updateMicroText();
  }

  displayAddOnTestToggle(test: Test): boolean {
    return this.isAddOnTest(test) && (!this.isExistingAddOn(test) || test.isUncanceled) && this.edit;
  }

  // Using a method here to make this accessible to the template UX
  setIsAddOn(addedTest: Test, $event) {
    addedTest.setIsAddOn($event);

    if (typeof this.value !== 'string') {
      this.value.forEach((existingTest) => {
        if (existingTest.testId === addedTest.testId) {
          existingTest.setIsAddOn($event);
        }
      });
    }
  }

  isAddOnTest(test: Test): boolean {
    return (
      this.testCodesService.isExistingAccession() &&
      (!this.testCodesService.isOrderedTest(test.testId) || this.isExistingAddOn(test) || test.isUncanceled)
    );
  }

  isExistingAddOn(test: Test): boolean {
    return (this.testCodesService.isExistingAddOn(test.testId) && !test.isUncanceled) || false;
  }

  hasNewTest(): boolean {
    let hasNewAddon = false;

    if (Array.isArray(this.value)) {
      this.value.forEach((test) => {
        if (!hasNewAddon) {
          hasNewAddon = this.isAddOnTest(test) && (!this.isExistingAddOn(test) || test.isUncanceled);
        }
      });
    }

    return hasNewAddon;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  isRemovable(testId: string): boolean {
    return this.edit && !this.testCodesService.isOrderedTest(testId) && !this.testCodesService.isExistingAddOn(testId);
  }

  isUncancelable(test: Test): boolean {
    let isPreviousUncancel;
    if (typeof this.value !== 'string') {
      isPreviousUncancel =
        this.value.filter((existingTest) => existingTest.isUncanceled && existingTest.testId === test.testId).length >
        0;
    }

    return !isPreviousUncancel && this.isCanceledTest(test) && this.testCodesService.isOrderedTest(test.testId);
  }

  isExistingCanceledTest(test: Test): boolean {
    let canceled = false;

    this.canceledTests.forEach((cTest) => {
      if (cTest.testId === test.testId) {
        canceled = true;
      }
    });

    return canceled;
  }

  showCancelReasonSelector(test: Test): boolean {
    return (
      this.edit &&
      this.testCodesService.isExistingAccession() &&
      (this.testCodesService.isOrderedTest(test.testId) || this.testCodesService.isExistingAddOn(test.testId)) &&
      !test.isUncanceled
    );
  }

  showCancelReason(test: Test): boolean {
    return !this.edit && this.testCodesService.isExistingAccession() && this.isCanceledTest(test) && !test.isUncanceled;
  }

  showAccessionCancelReasonSelector(): boolean {
    return this.edit && this.testCodesService.isExistingAccession();
  }

  // Determine if a given test is canceled in the scope of the current client state
  isCanceledTest(test: Test): boolean {
    let isCanceled = false;
    if (Array.isArray(this.value)) {
      this.value.forEach((existingTest) => {
        if (existingTest.testId === test.testId && existingTest.cancelReasonCode && !isCanceled) {
          isCanceled = true;
        }
      });
    }

    return isCanceled;
  }

  thereIsTestWithSameTestCode(value: Test): boolean {
    let sameTestCode = false;

    if (Array.isArray(this.value)) {
      const test = value;

      this.value.forEach((existingTest) => {
        // Using testCode here instead of testId intentionally: cat vs dog GCUPI scenario
        if (existingTest.testCode === test.testCode && !sameTestCode) {
          sameTestCode = true;
        }
      });
    }

    return sameTestCode;
  }

  isTestWithSameTestCodeNotCanceled(test: Test): Test {
    if (Array.isArray(this.value)) {
      const testsWithSameTestCodeNotCanceled = this.value.filter(
        (existingTest) => existingTest.testCode === test.testCode && !existingTest.cancelReasonCode
      );
      return testsWithSameTestCodeNotCanceled.length ? testsWithSameTestCodeNotCanceled[0] : null;
    }
    return null;
  }

  updateCancelReason(test: Test, $event) {
    test.updateCancelReason($event?.target.value);
  }

  getLocalizedCancelReason(test: Test): string {
    const cancelReasons = this.cancelReasons;
    return cancelReasons.filter((cancelReason) => cancelReason.code === test.cancelReasonCode)[0]?.description;
  }

  handleEscape($event) {
    if (this.displayedData?.length > 0) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      this.edit = false;
      this.hideOptions();
      this.focusInput();
    }
  }

  validate(ctrl: FormControl): ValidationErrors | null {
    if (ctrl.dirty && this.searching === false) {
      const valueInField = !!(this.displayValue.length || this.value.length);

      if (!valueInField) {
        return {
          invalid: true,
          required: { message: 'ERRORS_AND_FEEDBACK.REQUIRED', value: true },
        };
      } else if (
        this.displayValue &&
        !this.displayedData.length &&
        this.displayValue !== this.missingInformationGlyph
      ) {
        return {
          invalid: true,
          required: { message: 'ERRORS_AND_FEEDBACK.NO_MATCHES', value: true },
        };
      }
    } else {
      return null;
    }
  }
}
